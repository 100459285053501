import './details_product.css';
import { MalhaTamanhoPng, MalhaTamanhoWebp } from '../../images';
import ImageComponent from '../image/image';


const DetailsProduct = () => {
    return (
        <div className='DetailsProduct'>
            <div className='DetailsProductContainer'>
                <div className='title'>Durabilidade Sem Igual</div>
                <div className='description'>Nunca mais se preocupe com malhas fardo que desfiam. Nossos produtos são construídos para durar, utilizando materiais de alta qualidade e técnicas de fabricação avançadas. Confie em nós para proteger sua carga com malhas que resistem ao teste do tempo</div>
                <div className="DetailsProduct-button">
                    <a className="DetailsProduct-button-text" href="https://wa.link/6e1h9f">Fale conosco</a>
                </div>
            </div>
            <ImageComponent
                src={MalhaTamanhoPng}
                alt="Imagem Promocional da Malha"
                className="ImageDetailsProduct"
                webSRC={MalhaTamanhoWebp}
            />
        </div>
    );
};

export default DetailsProduct;
