import React from 'react';
import { Link } from 'react-router-dom';
import "./NotFound.css"

const NotFound: React.FC = () => {
  return (
    <div className="NotFound">
      <h1 className="NotFoundTitulo">404 - Página não encontrada</h1>
      <h2 className="NotFoundPequeno">Desculpe, a página que você está procurando não existe.</h2>
      <Link to="/">
        <button>Voltar à Página Principal</button>
      </Link>
    </div>
  );
};

export default NotFound;
