import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Banner1Png, Banner1Webp, Banner2Png ,
Banner2Webp ,Banner3Png, Banner3Webp, Banner4Png, Banner4Webp, Banner5Png, Banner5Webp}  from "../../images/index";
import './carousel.css';
import ImageComponent from '../image/image';


const Carousel: React.FC = () => {
  const images = [
    { id: 1, src: Banner1Png, alt: 'Imagem Promocional 1', webSRC: Banner1Webp },
    { id: 2, src: Banner2Png, alt: 'Imagem Promocional 2', webSRC: Banner2Webp },
    { id: 3, src: Banner3Png, alt: 'Imagem Promocional 3', webSRC: Banner3Webp },
    { id: 4, src: Banner4Png, alt: 'Imagem Promocional 4', webSRC: Banner4Webp },
    { id: 5, src: Banner5Png, alt: 'Imagem Promocional 5', webSRC: Banner5Webp },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="carousel-container">
     <a className='carousel-titulo'> Malha Fardo</a>
     <a className='carousel-text'>Conheça mais os benefícios da malha fardo.</a>
    <Slider {...settings} className="custom-slider">
      {images.map((image) => (
        <div key={image.id}>
          <ImageComponent src={image.src} webSRC={image.webSRC} alt={image.alt}  className="carousel-image"/>
        </div>
      ))}
    </Slider>
    </div>
  );
};

export default Carousel;
