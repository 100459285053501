import React from 'react';
import { Header, CookieConsentBanner, NotFound } from '../components/index';
import { LogoSvg } from '../images/index';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const MainPage: React.FC = () => {
  return (
      <div>
        <CookieConsentBanner />
        <Header />
        <FloatingWhatsApp
          phoneNumber="+5511971128571"
          accountName="Kamamuh"
          statusMessage="Fábrica de Fardo"
          chatMessage="Olá, como posso ajudar?"
          placeholder="Digite uma mensagem"
          avatar={LogoSvg}
          allowEsc
          allowClickAway
          notification
          notificationSound
        />
        <NotFound/>

      </div>

  );
};

export default MainPage;
