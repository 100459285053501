import { LogoSvg } from "../../images/index";
import './Header.css';

const Header = () => {
  return (
    <header>
      <div className="Header">
       <div className="Header-Logo">
          <img src={LogoSvg} className="Header-logo" alt="logo" />       
      </div>
        <div className="Header-button">
          <a className="Header-button-text" href="https://wa.link/6e1h9f">Fale conosco</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
