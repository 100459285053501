import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Aceitar"
      cookieName="myCookieConsent"
      style={{ background: '#fff', color: '#B10D14' }}
      buttonStyle={{ background: '#B10D14', color: '#fff', fontSize: '16px' }}
    >
      Este site utiliza cookies para aprimorar a experiência do usuário.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
