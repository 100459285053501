import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  webSRC: string;
}

const ImageComponent: React.FC<ImageProps> = ({ src, alt, className }) => {

  return (
    <img
      alt={alt}
      src={src}
      className={className}
    />
  );
};

export default ImageComponent;
