import './landpage.css';
import { BannerLandpageWebp, BannerLandpagePng } from '../../images';
import ImageComponent from '../image/image';



const Landpage = () => {
  return (
    <div className="Landpage">
      <ImageComponent
        src={BannerLandpagePng}
        alt="Imagem Promocional da Malha"
        className="ImagemMobile"
        webSRC={BannerLandpageWebp}
      />
    </div>
  );
};

export default Landpage;