/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';


const Map = () => {
  const mapContainerStyle = {
    width: '20rem',
    height: '20rem',
    borderRadius: '5%',
  };

  const center = {
    lat: -23.52462539,
    lng: -46.63821629,
  };

  const markerPosition = {
    lat: -23.5246804,
    lng: -46.6385818,
  };

  const apiKey = 'AIzaSyAKNu03YOaF37yC98h1-0cF-OjWkfTgqxw';

  if (!apiKey) {
    return <div>Error: Google Maps API key is not defined.</div>;
  }

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [userLocation, setUserLocation] = useState<GeolocationPosition | null>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation(position);
        },
        (error) => {
          console.error('Erro ao obter a localização:', error.message);
        }
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
    }
  }, []); 

  const toggleInfoWindow = () => {
    setInfoWindowOpen(!infoWindowOpen);
  };

  const directionsCallback = (response?: google.maps.DirectionsResult | null, status?: google.maps.DirectionsStatus) => {
    if (status === 'OK') {
      setDirections(response || null);
    } else {
      console.error('Directions request failed');
    }
  };

  const handleDirectionsClick = () => {
    if (userLocation) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: new window.google.maps.LatLng(userLocation.coords.latitude, userLocation.coords.longitude),
          destination: new window.google.maps.LatLng(markerPosition.lat, markerPosition.lng),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        directionsCallback
      );
    }
  };

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
        <Marker position={markerPosition} onClick={toggleInfoWindow}>
          {infoWindowOpen && (
            <InfoWindow onCloseClick={toggleInfoWindow}>
              <div>
                <h2>Kamamuh Têxtil</h2>
                <p>Fardo para embalagens direto da fabrica!</p>
                <p>Endereço: Rua Mamoré 421 - Bom Retiro - SP</p>
                <button onClick={handleDirectionsClick}>Como Chegar</button>
              </div>
            </InfoWindow>
          )}
        </Marker>

        {directions && (
          <DirectionsRenderer options={{ directions: directions, suppressMarkers: true }} />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;