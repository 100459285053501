import { FaWhatsapp } from "react-icons/fa6";
import "./contact_hero.css"


const ContactHero = () => {
    return (
        <div className="contact-hero">
            <div className="container">
                <a className="contact-text">Conheça mais da </a>
                <a className="contact-text-red">nossa fábrica!</a>
            <div className="contact-description">Venha conhecer um pouco mais sobre nossa fábrica.</div>
        </div>
        <div className="contact">
             <FaWhatsapp className="contact-text-red"/>
             <a className="contact-text">(11) 97112-8571</a>
        </div>
        </div>
    );
};

export default ContactHero;
