


import {   ProducaoPng, ProducaoWebp, QualidadePng, QualidadeWebp, RevendedorPng, RevendedorWebp, } from '../../images';
import ImageComponent from "../image/image";
import "./main_hero.css"


const MainHero = () => {
  return (
    <div className="main-hero">
      <div className="main-section">
        <div className="section-title">Malha Fardo Embalagem</div>
        <div className="section-description">Compromisso com a Qualidade.</div>
      </div>
      <div className="second-hero">

        <div className="hero">
          <ImageComponent
            src={RevendedorPng}
            alt="icone ofertas"
            className="icone"
            webSRC={RevendedorWebp}
          />
          <div className="title">Ofertas para revendedor</div>
          <div className="description">Temos ofertas especiais para lojistas e revendedores. Entre em contato e faça sua cotação hoje mesmo!</div>
        </div>

        <div className="hero">
        <ImageComponent
            src={ProducaoPng}
            alt="icone producao"
            className="icone"
            webSRC={ProducaoWebp}
          />
          <div className="title">Produção Interna</div>
          <div className="description">Nossas malhas são de produção própria, assim garantindo um produto da melhor qualidade!</div>
        </div>

        <div className="hero">
        <ImageComponent
            src={QualidadePng}
            alt="icone qualidade"
            className="icone"
            webSRC={QualidadeWebp}
          />
          <div className="title">Produtos de Alta Qualidade</div>
          <div className="description">Nossa malha fardo não desfia, tem alta durabilidade, feita 100% de poliéster e diversos tamanhos!</div>
        </div>

      </div>

    </div>
  );
}

export default MainHero;