import './product.css';
import { MalhaTamanhoPng, MalhaTamanhoWebp }  from '../../images';
import ImageComponent from '../image/image';


const Product = () => {
  return (
    <div className='Product'>
        <ImageComponent
        src={MalhaTamanhoPng}
        alt="Imagem Promocional da Malha"
        className="ImageProduct"
        webSRC={MalhaTamanhoWebp}
        />
        <div className='ProductContainer'>
        <div className='title'>Malha Fardo para transportadora</div>
        <div className='description'>Nossas malhas fardo são produzidas internamente, com um compromisso inabalável com a excelência. Disponíveis em três tamanhos - 45cm, 50cm e 60cm - elas são projetadas para atender às diversas necessidades do mercado, garantindo segurança e proteção em cada embalagem.</div>
        <div className="product-button">
          <a className="product-button-text" href="https://wa.link/6e1h9f">Fale conosco</a>
        </div>
        </div>
        </div>
          );
};

export default Product;
