import "./section.css"
import Map from "./map"
import { LogoPng, LogoPngWebp }  from '../../images';
import ImageComponent from "../image/image";





const MapSection = () => {
    return (
        <div className="Section">
            <div className="Container">
                <div>
                    <div className="infos">
                        <ImageComponent
                            src={LogoPng}
                            alt="Logo"
                            className="logo"
                            webSRC={LogoPngWebp}
                        />

                        <div className="text">Copyright © 2024 Kamamuh Têxtil </div>
                        <div className="text">Todos direitos reservados </div>
                    </div>

                </div>
                <div className="map">
                    <div className="text">Onde estamos</div>
                    <div className="text">R. Mamoré, 421 - Bom Retiro, São Paulo - SP</div>
                    <Map />
                </div>

            </div>
        </div>

    );
};

export default MapSection;